<template>
  <v-expansion-panels
    :accordion="accordion"
    :active-class="activeClass"
    :dark="dark"
    :disabled="disabled"
    :flat="flat"
    :focusable="focusable"
    :hover="hover"
    :inset="inset"
    :light="light"
    :mandatory="mandatory"
    :max="max"
    :multiple="multiple"
    :popout="popout"
    :readonly="readonly"
    :tag="tag"
    :title="title"
    :value="value"
    :value-comparator="valueComparator"
  >
    <v-expansion-panel
      v-for="({ contentProps, headerProps, item, panelProps }, panelIndex) in panels"
      v-bind="panelProps"
      :key="panelIndex"
      :style="`--expansion-panel-color: ${contentProps.color || headerProps.color || defaultColor}`"
    >
      <v-expansion-panel-header v-bind="headerProps" :color="headerProps.color || defaultColor">
        <slot name="header" :item="item" :index="panelIndex"></slot>
        <template v-slot:actions>
          <slot name="header-actions" :item="item" :index="panelIndex"></slot>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-bind="contentProps">
        <slot name="content" :item="item" :index="panelIndex"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
/**
 * @typedef {Object} ExpansionPanelProps
 * @param {string} activeClass
 * @param {boolean} disabled
 * @param {boolean} readonly
 * @param {(boolean) => void} change
 * @param {(MouseEvent) => void} click
 */

/** 
 * @function ExpansionPanelFn
 * @param {*} item
 * @returns {ExpansionPanelProps}
 */

/**
 * @typedef {Object} ExpansionPanelHeaderProps
 * @param {string} color
 * @param {boolean} disableIconRotate
 * @param {string} expandIcon
 * @param {boolean} hideActions
 * @param {boolean | object} ripple
 * @param {(MouseEvent) => void} click
 */

/** 
 * @function ExpansionPanelHeaderFn
 * @param {*} item
 * @returns {ExpansionPanelHeaderProps}
 */

/**
 * @typedef {Object} ExpansionPanelContentProps
 * @param {string} color
 * @param {boolean} eager
 */

/** 
 * @function ExpansionPanelContentFn
 * @param {*} item
 * @returns {ExpansionPanelContentProps}
 */

/** Um ou mais paneis expansiveis */
export default {
  computed: {
    defaultColor: function () {
      return this.getClient().isClient ? "#6BB4BA" : "#81B4EA";
    },
    panels: function () {
      if (!this.items) {
        return [];
      }
      
      const items = Array.isArray(this.items) ? this.items : [this.items];
      return items.map((item) => ({
        item,
        contentProps: typeof this.contentProps === 'function' ? this.contentProps(item) : this.contentProps || {},
        headerProps: typeof this.headerProps === 'function' ? this.headerProps(item) : this.headerProps || {},
        panelProps: typeof this.panelProps === 'function' ? this.panelProps(item) : this.panelProps || {},
      }));
    },
  },
  props: {
    accordion: Boolean,
    activeClass: String,
    /** @type {ExpansionPanelContentProps | ExpansionPanelContentFn} */
    contentProps: [Object, Function],
    dark: Boolean,
    disabled: Boolean,
    flat: Boolean,
    focusable: Boolean,
    /** @type {ExpansionPanelHeaderProps | ExpansionPanelHeaderFn} */
    headerProps: [Object, Function],
    hover: Boolean,
    inset: Boolean,
    items: [Object, Array],
    light: Boolean,
    mandatory: Boolean,
    max: [Number, String],
    multiple: Boolean,
    /** @type {ExpansionPanelProps | ExpansionPanelFn} */
    panelProps: [Object, Function],
    popout: Boolean,
    readonly: Boolean,
    tag: String,
    title: Boolean,
    value: {},
    valueComparator: Function,
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  $panel-color: var(--expansion-panel-color);

  .v-expansion-panel::before {
    box-shadow: 0px 3px 1px -2px $panel-color, 0px 2px 2px 0px $panel-color,
      0px 1px 5px 0px $panel-color;
  }

  .v-expansion-panels .v-expansion-panels .v-expansion-panel::before {
    box-shadow: 0px 3px 1px -2px $panel-color, 0px 2px 2px 0px $panel-color,
        0px 1px 5px 0px $panel-color;
  }

  .v-expansion-panel-content__wrap {
    padding: 16px 24px;
  }
}
</style>
